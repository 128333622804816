<template>
  <div class="d-flex w-100 overflow">
    <div class="overflow-scroll p-1">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
           <span><feather-icon icon="MenuIcon"/></span>
        </b-button>
        <b-button 
          variant="outline-secondary" 
          class="size-button-center m-5rem icon-setting-sider d-xl-none"
          @click="sidebar_chat_responsive = true"
        >
          <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
        </b-button>
      </div>
      <div class="h-100 flex-grow"  ref="main-container-chat">
        <div class="default-conversation" v-if="Object.keys(conversation_selected).length === 0">
          <div class="sender mb-2" :class="{'receiver': i === 2}" v-for="i in 3" :key="i" :style="`opacity: ${1 - 0.23 * i}`">
            <div class="avatar"></div>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': i === 2}">
              <div class="title-conversation"></div>
              <div class="message-bubble"></div>
            </div>
          </div>
        </div>

        <div class="conversation-chat" v-if="Object.keys(conversation_selected).length > 0">
          <div class="sender mb-2" :class="{'receiver': msg.role === 'user'}" v-for="(msg, index) in conversation_selected.messages" :key="index">
            <b-avatar class="avatar" :src="msg.role === 'user' ? image_profile : require('@/assets/images/icons/chat-bot.svg')"></b-avatar>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': msg.role === 'user'}">
              <div class="title-conversation w-100">
                <div v-if="msg.role === 'assistant'" class="d-flex justify-content-between align-items-start">
                  <span class="avenir-medium cursor-pointer d-block" @click="copyAction(msg.content)" v-if="!msg.type">{{$t('ia.chat.copy')}}</span>
                  <span v-else class="d-block"></span>
                  <b-dropdown
                    no-caret
                    right
                    variant="link"
                    class="avenir-medium"
                    id="dropdown-options-chat"
                  > 
                    <template #button-content>{{$t('ia.chat.actions')}}
                      <feather-icon icon="ChevronDownIcon"></feather-icon>
                    </template>
                    <div v-if="!msg.type">
                      <b-dropdown-item 
                        v-for="action in actions_message" 
                        :key="action.value" 
                        @click="actionMessage(action.value, msg.content)"
                        :disabled="getCredits('actions_message') <= 0"
                      >
                        <div class="d-flex justify-content-between">
                          {{action.text}} 
                          <span class="icon-ia-actions">{{ getCredits('actions_message') >= 0 ? getCredits('actions_message') : $t('ia.general.insufficientCredits') }} <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon></span></div> 
                      </b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="sidebar_export_chat = true; message_selected = msg.content">{{ $t('tracking.export') }}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="actionMessage('generate_image', msg.content)">
                        <div class="d-flex">
                          <div>{{ $t('ia.chat.generateImages') }}</div>
                          <div class="d-flex justify-content-between">
                            <span class="icon-ia-actions">{{ getCredits('images_message') >= 0 ? getCredits('images_message') : $t('ia.general.insufficientCredits') }} <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon></span></div> 
                        </div>
                      </b-dropdown-item>
                    </div>
                    <div v-else>
                      <b-dropdown-item @click="downloadAllFiles(msg.content)">
                        {{ $t('ia.chat.downloadAll') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="actionMessage('generate_image', msg.original_message)" :disabled="!msg.original_message">
                        <div class="d-flex justify-content-between">
                          {{ $t('ia.chat.generateAdditionalImages') }}
                          <span class="icon-ia-actions">{{ getCredits('images_message') >= 0 ? getCredits('images_message') : $t('ia.general.insufficientCredits') }} <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon></span></div> 
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </div>
              <div class="message-bubble">
                <div v-if="!msg.type"> {{ msg.content }}</div>
                <div v-else class="row"> 
                  <div
                    v-for="(data, index) in msg.content"
                    :class="'container-aspect-ratio col-12 col-sm-6 col-md-3 mb-1'"
                    :key="index"
                    :ref="`aspect_ratio_box_${index}`"
                  >           
                    <div class="h-100 aspect-ratio">
                      <div v-if="true" class="w-100 h-100 position-relative">
                        <div class="container-dropdown-image p-03" @click="downloadFile(data)">
                          <feather-icon icon="DownloadIcon" class="text-white"></feather-icon>
                        </div>
                        <img :src="data" class="img-ia"/>
                        <div class="container-ia-svg">
                          <img :src="require('@/assets/images/icons/ia.svg')" :alt="`ia-svgd`" class="ia-svg-icon">
                        </div>
                      </div>
                      <b-skeleton class="skeleton-loading-ia" v-else></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="background-input-botton">
        <small class="small-text">{{$t('ia.chat.textError')}}</small>
        <div class="d-flex justify-content-between align-items-center div-input-button">
          <b-form-input 
            autocomplete="off" 
            :placeholder="placeholder_text" 
            class="size-input" 
            v-model="query" 
            @keyup.enter="chat" 
            ref="textInput"
            @focus="onFocusInputSearch"
            @blur="onBlurInputSearch"
          />
          <b-button
            class="generate-img-ai"
            v-if="!loader_chat"
            @click="chat"
            :disabled="!query"
          >
          <feather-icon icon="SendIcon" class="send-icon"></feather-icon>
          <b-badge class="badge-button-ia">
            <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>
            {{ getCredits() >= 0 ? getCredits() : $t('ia.general.insufficientCredits') }}
          </b-badge>
          </b-button>
          <b-skeleton v-else type="button" class="generate-img-ai"></b-skeleton>
        </div>
      </div>
    </div>

    <b-card class="card-chat-history h-100 d-none d-xl-block">
      <b-button variant="blue-button" class="blue-button w-100" @click="startNewChat()">{{ $t('ia.chat.newChat')}}</b-button>
      <div class="border-top mt-2 pt-2 overflow-scroll-y">
        <div 
          class="chat-list"
          :ref="`chat-list-${index}`"
          v-for="(i, index) in conversations"
          :key="i.uuid"
          @click="openConversation(i, index)"
          :class="{ 'active-chat-list': index === selectedIndex }"
        >
          <div class="d-flex w-100">
            <div :ref="`title-container-${index}`" class="w-100">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex">
                  <feather-icon icon="MessageSquareIcon" class="icon-chat-list"></feather-icon>
                  <span class="text-title-span">
                    {{ i.title }}
                  </span>
                </div>
                <div v-if="isActiveList(index) && !is_editing_title" class="d-flex">
                  <feather-icon icon="EditIcon" class="icon-chat-list mr-1" @click="editTitle(index)"></feather-icon>
                  <feather-icon icon="TrashIcon" class="icon-chat-list disabled"></feather-icon>
                </div>
              </div>
            </div>
            <div v-if="isActiveList(index)" class="w-auto">
              
              <div v-if="is_editing_title" class="d-flex align-items-center">
                <b-form-input ref="form-input-title" v-model="title_chat"></b-form-input>
                <feather-icon icon="CheckIcon" class="icon-chat-list mr-1 ml-1" @click="editTitleAction(i, index)"></feather-icon>
                <feather-icon icon="XIcon" class="icon-chat-list" @click="cancelEdit(index)"></feather-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-sidebar v-model="sidebar_chat_responsive" backdrop shadow right>
      <div class="p-1 card-chat-history h-100">
        <b-button variant="blue-button" class="blue-button w-100" @click="startNewChat()">{{ $t('ia.chat.newChat')}}</b-button>
        <div class="border-top mt-2 pt-2 overflow-scroll-y">
          <div 
            class="chat-list"
            :ref="`chat-list-${index}`"
            v-for="(i, index) in conversations"
            :key="i.uuid"
            @click="openConversation(i, index)"
          >
            <div class="d-flex w-100">
              <div :ref="`title-container-${index}`" class="w-100">
                <div class="d-flex justify-content-between w-100">
                  <div class="d-flex">
                    <feather-icon icon="MessageSquareIcon" class="icon-chat-list"></feather-icon>
                    <span class="text-title-span">
                      {{ i.title }}
                    </span>
                  </div>
                  <div v-if="isActiveList(index) && !is_editing_title" class="d-flex">
                    <feather-icon icon="EditIcon" class="icon-chat-list mr-1" @click="editTitle(index)"></feather-icon>
                    <feather-icon icon="TrashIcon" class="icon-chat-list disabled"></feather-icon>
                  </div>
                </div>
              </div>
              <div v-if="isActiveList(index)" class="w-auto">
                
                <div v-if="is_editing_title" class="d-flex align-items-center">
                  <b-form-input ref="form-input-title" v-model="title_chat"></b-form-input>
                  <feather-icon icon="CheckIcon" class="icon-chat-list mr-1 ml-1" @click="editTitleAction(i, index)"></feather-icon>
                  <feather-icon icon="XIcon" class="icon-chat-list" @click="cancelEdit(index)"></feather-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-sidebar>
    <b-sidebar v-model="sidebar_export_chat" backdrop shadow right no-header>
      <div class="d-flex justify-content-between border-bottom p-1 mb-1">
        <h4>{{ $t('tracking.export') }}</h4>
        <feather-icon icon="XIcon" class="close-icon-sidebar-export" size="25" @click="sidebar_export_chat = false"/>
      </div>

      <div class="d-flex flex-column p-1">
        <strong>{{ $t('mediaKits.download') }}</strong>
        <p class="avenir-medium">{{ $t('ia.chat.saveFile') }}</p>
        <b-button variant="outline-secondary mb-1 avenir-medium text-left" @click="exportMessageAction(message_selected, 'txt')"><b-icon icon="file-earmark-text-fill"></b-icon> {{ $t('ia.chat.textFile') }}</b-button>
        <b-button variant="outline-secondary avenir-medium text-left" @click="exportMessageAction(message_selected, 'doc')"><b-icon icon="file-earmark-word-fill"></b-icon> {{ $t('ia.chat.wordDocument') }}</b-button>
      </div>
    </b-sidebar>
  </div>
</template>
    
<script>

import {
  BFormInput,
  BBadge,
  BButton,
  BSkeleton,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
} from 'bootstrap-vue';
import { chat, getConversations, editTitleChat, actionsMessage, exportMessage, getPriceActions } from '@/services/ia';
import { nextTick } from '@vue/composition-api';
import config from '@/services/config';
import { getUserData } from '@/libs/utils/user';
import { downloadFile } from '@/libs/utils/others';
import service from "@/services/dashboard";

const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'chat',
  components: {
    BFormInput,
    BButton,
    BBadge,
    BSkeleton,
    BCard,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSidebar,
  },
  data() {
    return {
      downloadFile,
      image_profile: this.getImgProfile(getUserData().profile_image),
      loader_chat: false,
      conversations: [],
      conversation_selected: {},
      placeholder_text: '',
      query: '',
      cancel_token_estimated: null,
      axios: null,
      estimated_credits: 0,
      estimated_credits_loaded: 0,
      is_editing_title: false,
      title_chat: '',
      timeout: null,
      sidebar_chat_responsive: false,
      user: '',
      is_focus: false,
      actions_message: [
        {
          text: this.$t('ia.chat.rephrase'),
          value: 'rephrase'
        },
        {
          text: this.$t('ia.chat.elaborate'),
          value: 'elaborate'
        },
        {
          text: this.$t('ia.chat.summarize'),
          value: 'summarize'
        },
        {
          text: this.$t('ia.chat.regenerate'),
          value: 'regenerate'
        }
      ],
      message_selected: null,
      actions_message_credit: null,
      images_message_credit: null,
      sidebar_export_chat: false, 
      selectedIndex: null
    }
  },
  props: {
    credits_avaible: {
      type: Number,
      default: 0
    }
  },
  async mounted() {
    
    this.startAnimation();
    this.axios = (await import('axios')).default;

    setTimeout(() => {
      this.getConversations()
      this.getPriceActions();
    }, 1000);
  },
  created() {
    this.$root.$on('chatgpt_response_ws', (data) => {
      this.loader_chat = false;
      this.conversation_selected = data.body;
      const index = this.conversations.findIndex(i => i.uuid === data.body.uuid)      
      this.conversations[index] = data.body;
      this.$emit('update_credits', this.estimated_credits_loaded);
      this.$root.$emit('update_credits_drop', this.estimated_credits_loaded)
      setTimeout(() => {
        nextTick(() => { this.scrollToBottomInChatLog() })
      }, 1)
    })
  },
  methods: {
    getPriceActions() {
      getPriceActions().then((response) => {
        this.actions_message_credit = response.action_on_message;
        this.images_message_credit = response.generate_images;
      })
    },
    actionMessage(action, message) {
      this.loader_chat = true;
      this.estimated_credits_loaded = (action === 'generate_image') ? this.images_message_credit : this.actions_message_credit;
      const data = {
        action,
        message,
        chat_uuid: this.conversation_selected.uuid
      }
      actionsMessage(data).then(() => {})
    },
    exportMessageAction(message, type_export) {
      const data = {
        type_export,
        message,
        chat_uuid: this.conversation_selected.uuid
      }
      exportMessage(data).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.conversation_selected.title}.${type_export === 'doc' ? 'docx' : 'txt'}`); // o el nombre de archivo que prefieras
        document.body.appendChild(link);
        link.click();
      })
    },
    downloadAllFiles(files) {
      files.forEach(file => {
        downloadFile(file);
      });
    },
    onFocusInputSearch() {
      this.is_focus = true;
      this.placeholder_text = '';
    },
    onBlurInputSearch() {
      this.is_focus = false;
      this.startAnimation();
    },
    startAnimation() {
      const placeholder_text = this.$t('creator.sendMessage');
      const speed = 50;
      this.animatePlaceholder(placeholder_text, speed);
    },
    getCredits(actions_message = 'message') {
      if (actions_message === 'message') return this.credits_avaible >= this.estimated_credits ? this.estimated_credits : -1;
      else if (actions_message === 'actions_message') return this.credits_avaible >= this.actions_message_credit ? this.actions_message_credit : -1;
      else if (actions_message === 'images_message') return this.credits_avaible >= this.images_message_credit ? this.images_message_credit : -1;
    },
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    async typeWriter(text, index, speed) {
      if (index < text.length && !this.is_focus) {
        this.placeholder_text += text.charAt(index);
        await this.sleep(speed);
        await this.typeWriter(text, index + 1, speed);
      }
    },
    async animatePlaceholder(text, speed) {
      this.placeholder_text = '';
      await this.typeWriter(text, 0, speed);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    startNewChat() {
      this.conversation_selected = {};
      this.clearClassesForClick();
    },
    estimationCredits() {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_token_estimated) this.cancel_token_estimated()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/ai/estimated-credits/`,
        data: {
          text: this.query,
          model: 'gpt-3.5-turbo'
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_token_estimated = c;
        })
      }).then((response) => {
        this.estimated_credits = response.data.response.estimated_credits;
      })
    },
    chat() {
      this.estimated_credits_loaded =  this.estimated_credits
      if (this.conversation_selected.uuid) {
        this.conversation_selected.messages.push({role: 'user', content: this.query})
        setTimeout(() => {
          nextTick(() => { this.scrollToBottomInChatLog() })
        }, 1)
      }
      this.loader_chat = true;
      const data = {
        model: 'gpt-3.5-turbo',
        text: this.query,
        prev_chat: this.conversation_selected.uuid
      }
      this.query = '';

      chat(data).then((response) => {
        
        if (!this.conversations.find(i => i.uuid === response.uuid)) {
          response.title = response.messages[0].content;
          this.conversations.unshift(response)
          setTimeout(() => {
            this.openConversation(response, 0)
          }, 100);
        }
      })
    },
    getConversations() {
      getConversations({}).then((response) => {
        this.conversations = response.results;
        const chat_uuid = this.$route.params.uuid;
        
        if (chat_uuid) { 
          const index = this.conversations.findIndex(chat => chat.uuid === chat_uuid);
          this.selectedIndex = index;
          this.$nextTick(() => {
            const main_container = this.$refs['main-container-chat'];
            main_container.scrollTop = main_container.scrollHeight;
          });
          
          this.openConversation(this.conversations.find(chat => chat.uuid === chat_uuid), index);
        }
      })
    },
    clearClassesForClick() {
      for (let index = 0; index < this.conversations.length; index++) {
        const ref = this.$refs[`chat-list-${index}`];
        if (ref && ref.length > 0) {
          ref[0].classList.remove('active-chat-list');
        }
      }
    },
    openConversation(conversation, index) {
      this.$router.push({ name: 'brandme_AI', params: { section: 'chat', category: 'chat-gpt', uuid: conversation.uuid}}).catch(() => {})
      this.conversation_selected = conversation;
      this.clearClassesForClick();
      const ref = this.$refs[`chat-list-${index}`];
      if (ref && ref.length > 0) {
        ref[0].classList.add('active-chat-list');
        setTimeout(() => {
          nextTick(() => { this.scrollToBottomInChatLog() })
        }, 1);
      }
    },
    isActiveList(index) {
      if (this.$refs[`chat-list-${index}`]) return this.$refs[`chat-list-${index}`][0].classList.contains('active-chat-list')
      return false
    },
    
    editTitle(index) {
      this.is_editing_title = true;
      this.title_chat = this.conversation_selected.title;
      this.hideTitle(index)
      
    },
    hideTitle(index) {
      for (let i = 0; i < this.conversations.length; i++) {
        const container = this.$refs[`title-container-${i}`][0]
        container.classList.remove('d-none');
      }
      this.$refs[`title-container-${index}`][0].classList.add('d-none');
    },
    cancelEdit(index) {
      const container = this.$refs[`title-container-${index}`][0]
      container.classList.remove('d-none');
      this.is_editing_title = false
    },
    editTitleAction(chat, index) {
      editTitleChat(chat.uuid, {title: this.title_chat}).then((response) => {
        chat.title = response.title;
        this.cancelEdit(index);
      })
    },
    scrollToBottomInChatLog() {
      const main_container = this.$refs['main-container-chat']
      main_container.scrollTop = main_container.scrollHeight;
    },
    copyAction(msg) {
      this.$copyText(msg).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t('ia.chat.msjCopy'),
              icon: "CopyIcon",
              text: '',
              variant: 'success'
            },
          });
        })
      });
    },
  },
  watch: {
    query(val) {
      if (val) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.estimationCredits(), 300);
      } else if (val === '') this.estimated_credits = 0;
    }
  }
}
</script>
<style lang="scss" scoped>
.p-03 {
  padding: 0.3em;
}
.container-dropdown-image {
  background-color: rgba(0, 0, 0, 0.699);
  position: absolute;
  top: 5%;
  border-radius: 0.5em;
  right: 5%;
  transform: translateX(70px);
}
.container-aspect-ratio {
  &:hover {
    .container-dropdown-image {
      animation: dropdown-animation 300ms forwards;
    }
  }
  &:not(:hover) { 
    .container-dropdown-image {
      animation: dropdown-animation-back 300ms forwards;
    }
  }
}
@keyframes dropdown-animation {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes dropdown-animation-back {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}

.aspect-ratio {
  background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(219,219,219,1) 100%);
  display: flex;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  
  .skeleton-loading-ia {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .img-ia {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-ia-svg {
    position: absolute;
    width: 5%;
    height: 5%;
    right: 5%;
    background-color: white;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ia-svg-icon {
      width: 170%;
      height: 170%;
    }
  }
}
.close-icon-sidebar-export:hover {
  cursor: pointer;
  background-color: #eaeaea;
  border-radius: 100%;
}
.size-input::placeholder {
  color: #70747c;
}
.overflow {
  height: 100%;
}
.overflow-scroll-y {
  height: 92%;
  overflow-y: scroll;
}
.default-conversation {
  width: 100%;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 110px;
      height: 20px;
      margin-bottom: 0.4em;
      background-color: #E9ECEF;
    }
    .message-bubble {
      width: 95%;
      height: 115px;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    .message-bubble  {
      &::after {
        border: 10px solid #e9ecef !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}
.flex-grow {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.conversation-chat {

  width: 100%;
  height: 100% !important;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 95% !important;
      height: 20px;
      margin-bottom: 0.4em;
    }
    .message-bubble {
      width: 95%;
      height: auto;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;
      color: black;
      font-family: 'avenir-medium';
      padding: 1.6em;
      white-space: pre-line;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        top: 0;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    // margin-top: -em;
    .message-bubble  {
      background-color: #55a6c4;
      color: white;
      &::after {
        border: 10px solid #55a6c4 !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
        top: 0;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}

.overflow-scroll {
  // overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  .mr-05 {
    margin-right: 0.5em;
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .background-input-botton {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .small-text {
      background-color: #eeeeee;
      padding: 0.2em 0.5em;
      font-family: 'avenir-light';
      border-radius: 1em;
      margin: 0.4em 0;
    }
    .div-input-button {
      width: 100%;
      backdrop-filter: blur(2px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5em;
      .send-icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5em;
      }
      .badge-button-ia {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        line-height: 10px;
        background-color: #40736E;
      .icon-ia {
          width: 14px;
          height: 14px;
          margin-right: 0.2em;
        }
      }
      .generate-img-ai{
        height: 42px;
        display: flex;
        align-items: center;
        background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
      }
      .size-input{
        background-color: transparent;
        height: 100%;
        width: 80%;
        font-size: 15px;
        font-weight: bold;
        border: none;
        box-shadow: none !important;
        font-family: monospace !important;

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
.card-chat-history {
  width: 290px;
  flex-shrink: 0;
  margin: 0;
  overflow-y: scroll;
  // padding-bottom: 100px;
  .chat-list {
    // background-color: #dee2e6;
    padding: 1em;
    border-radius: 0.5em;
    font-family: 'avenir-medium';
    display: flex;
    position: relative;

    .actions-chat-list {
      box-shadow: 10px 10px 10px #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.5em;
    }

    &:hover {
      background-color: #dee2e6af;
      cursor: pointer;
    }
    .icon-chat-list {
      width: 20px;
      height: 20px;
      display: block;

      &.disabled {
        color: #bcbaba;
      }
    }
    .text-title-span {
      margin-left: 0.5em;
      width: auto;
      max-width: 150px;
      display: block;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .active-chat-list {
    background-color: #dee2e6 !important;
  }
}
</style>
<style lang="scss">
#dropdown-options-chat {
  .btn {
    padding: 0 !important;
    color: black;
  }
  .icon-ia-actions {
    margin-left: 1.5em;
    color: #5582f6;
    .icon-ia {
      width: 17px;
      height: 17px;
    }
  }
}
</style>
